@import './rem.scss';

@font-face {
  font-family: CustomFontLight;
  src: url("/fonts/Raleway-Light.ttf") /* EOT file for IE */
}

@font-face {
  font-family: CustomFontRegular;
  src: url("/fonts/Raleway-Regular.ttf") /* EOT file for IE */
}

@font-face {
  font-family: CustomFontBold;
  src: url("/fonts/Raleway-Bold.ttf") /* EOT file for IE */
}

@media (min-width: 320px) {
  html, body {
    font-family: CustomFontRegular, serif;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 14px;
  }
  header .top div {
    font-size: rem(18px);
    margin-top: rem(0px);
  }
  header .top-container-home .title2 {
    font-size: rem(16px);
  }
  .top-container-home {
    box-sizing: border-box;
    padding-left: rem(30px);
    padding-right: rem(30px);
  }
  header .top-container-home .top {
    padding-left: rem(30px);
    padding-right: rem(30px);
  }
  header .top-container .top {

  }
  header .top-container .top div {
    position: relative;
    top: rem(0px);
  }
  .padding-public {
    padding-left: rem(30px);
    padding-right: rem(30px);
    padding-bottom: rem(30px);
  }
  .footer-flex-row {
    display: flex;
    flex-direction: column;
  }
  .footer-menu {
    margin-right: rem(0px);
    margin-bottom: rem(30px);
  }
  .max-width {
    margin-left: rem(30px);
    margin-right: rem(30px);
  }
  .footer-menu div a {
    font-size: rem(16px);
  }
  .footer-title {
    font-size: rem(18px);
  }
  .div-contact-footer div font {
    font-size: rem(15px);
  }
  .div-contact-footer div {
    font-size: rem(16px);
  }
  footer .foot2 {
    padding: rem(66px) rem(30px) rem(50px) rem(30px);
  }
  .copyright {
    font-size: rem(14px);
  }
  header .top-container {
    height: rem(140px);
  }
  header .top-container .header-home-logo {
    z-index: 2;
    position: relative;
    width: rem(200px);
    height: rem(50px);
    background-image: url('/img/logo-royal.svg');
    background-size: cover;
    margin-top: rem(30px);
  }
  header .top-container .in-container {
    margin-left: rem(30px);
    margin-right: rem(30px);
  }
  .page-content {
    margin-top: rem(40px);
    width: 100%;
  }
  header .top-container-home {
    height: rem(390px);
  }
  header .top-container-home .header-home-logo {
    margin-top: rem(20px);
  }
  .hide-mobile {
    display: none;
  }
  input, textarea {
    font-size: rem(14px);
  }
  label {
    display: block;
    font-size: rem(14px);
    color: #333333;
    padding: rem(3px) rem(1px);
  }
  .button {
    height: rem(40px);
    line-height: rem(40px);
    text-align: center;
    display: block;
    margin-top: rem(20px);
    padding: rem(3px) rem(15px);
    color: white;
    border-radius: rem(30px);
    background-color: #24b47e;
    cursor: pointer;
    font-size: rem(18px);
  }
  header .top .contact-container div {
    font-size: rem(14px);
  }
}

@media (min-width: 720px) {
  html, body {
    font-family: CustomFontRegular, serif;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 1.1vw;
  }
  header .top div {
    font-size: rem(14px);
  }
  header .top-container-home .title2 {
    max-width: rem(500px);
    font-size: rem(18px);
  }
  header .top-container-home .top {
    padding-left: rem(60px);
    padding-right: rem(60px);
  }
  header .top-container .top {

  }
  .padding-public {
    max-width: rem(1300px);
    margin-left: rem(30px);
    margin-right: rem(30px);
    margin-bottom: rem(40px);
  }
  .footer-flex-row {
    display: flex;
    flex-direction: row;
  }
  .footer-menu {
    margin-right: rem(30px);
    margin-bottom: rem(0px);
  }
  .max-width {
    max-width: rem(1300px);
    margin-left: rem(50px);
    margin-right: rem(50px);
  }
  .footer-menu div a {
    font-size: rem(13px);
  }
  .footer-title {
    font-size: rem(14px);
  }
  .div-contact-footer div font {
    font-size: rem(12px);
  }
  .div-contact-footer div {
    font-size: rem(13px);
  }
  footer .foot2 {
    padding: rem(66px) rem(50px) rem(50px) rem(50px);
  }
  .copyright {
    font-size: rem(10px);
  }
  header .top-container {
    height: rem(120px);
  }
  header .top-container .header-home-logo {
    z-index: 2;
    position: relative;
    width: rem(200px);
    height: rem(50px);
    background-image: url('/img/logo-royal.svg');
    background-size: cover;
    margin-top: rem(10px);
  }
  header .top-container .in-container {
    margin-left: rem(60px);
    margin-right: rem(60px);
  }
  .page-content {
    margin-top: rem(40px);
    width: 100%;
  }
  header .top-container-home {
    height: rem(450px);
  }
  header .top-container-home .header-home-logo {
    margin-top: rem(-30px);
  }
  .hide-mobile {
    display: block;
  }
  input, textarea {
    font-weight: 400;
    font-size: rem(12px);
  }
  label {
    display: block;
    font-size: rem(12px);
    color: #333333;
    padding: rem(3px) rem(1px);
  }
  .button {
    height: rem(30px);
    line-height: rem(30px);
    text-align: center;
    display: table;
    margin-top: rem(20px);
    padding: rem(8px) rem(20px);
    color: white;
    border-radius: rem(30px);
    background-color: #24b47e;
    cursor: pointer;
    font-size: rem(14px);
  }
  header .top .contact-container div {
    font-size: rem(16px);
  }
}

@media (min-width: 1500px) {
  html, body {
    font-size: 16px;
  }
  header .top {
    //max-width: rem(1300px);
  }
}


header .top-container .top {
  font-family: "Open Sans", sans-serif;
  z-index: 2;
  max-width: rem(1300px);
  padding-left: rem(60px);
  margin-right: rem(60px);
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  margin-top: rem(0px);
  top: 0;
  display: flex;
  justify-content: flex-end;
}

header .top-container-home .top {
  font-family: "Open Sans", sans-serif;
  z-index: 2;
  max-width: rem(1300px);

  width: 100%;
  position: absolute;
  box-sizing: border-box;
  margin-top: rem(20px);
  top: 0;
  display: flex;
  justify-content: flex-end;
}

header .top div {
  border: 0px solid red;
  color: #cccccc;
  flex: 1;
  text-align: right;
}

header .top div.link {
  text-decoration: underline;
  cursor: pointer;
}

header .top div.link:hover {
  color: #ffffff;
}

header .top-container-home, header .top-container {
  display: flex;
  position: relative;
  width: 100%;
  background: #111111;
  z-index: 2;
  overflow: hidden;
  flex-direction: column;
}

header .top-container .in-container {
  position: relative;
  width: 100%;
  display: block;
  box-sizing: content-box;
  border: 0px solid red;
  max-width: rem(1300px);
  margin-top: rem(15px);
}


header .top-container-home:before, header .top-container:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.6;
}

header .top-container:before {
  background: url("/img/main.jpg") center top;
  background-size: cover;
}

header .top-container-home:before {
  background: url("/img/main.jpg") center center;
  background-size: cover;
}

header .top-container-home .title {
  font-family: CustomFontLight, serif;
  position: relative;
  z-index: 2;
  color: #ffffff !important;
  font-size: rem(25px);
  margin-top: rem(30px);
  text-align: center;
}

header .top-container .title {
  font-family: CustomFontLight, serif;
  position: relative;
  z-index: 2;
  color: #aaa !important;
  font-size: rem(14px);
  margin-top: rem(-3px);
  margin-left: rem(7px);
  text-align: left;
}

header .top-container-home .title2 {
  font-family: CustomFontLight, serif;
  position: relative;
  z-index: 2;
  color: #999999;
  margin-top: rem(25px);
  text-align: center;
}

header .top-container-home .header-home-logo {
  z-index: 2;
  position: relative;
  width: rem(330px);
  height: rem(80px);
  background-image: url('/img/logo-royal.svg');
  background-size: cover;
}

header .menu-display {
  display: flex;
  justify-content: center;
  margin-top: rem(18px);
  margin-bottom: rem(18px);

}

header .menu-display a {
  font-family: CustomFontRegular, serif;
  text-decoration: none;
  font-size: rem(16px);
  color: #000000;
  margin-left: rem(30px);
  margin-right: rem(30px);
  padding: rem(8px) rem(16px) rem(10px) rem(16px);
  border-radius: rem(30px);
}

header hr {
  margin-left: rem(60px);
  margin-right: rem(60px);
  border: 0;
  border-bottom: rem(1px) solid #dddddd;

}

header .menu-display a:hover {
  background: #D8D8D899;
  color: #333333;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center1500 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.footer_block_news {
  display: block;
}

.footer_block_menu {
  display: block;
}

footer {
  color: white;
}

footer .foot1 {
  /* Rectangle 7: */
  background: #393939;
  padding: rem(25px) 0;
}

footer .foot2 {
  background: #4C4A4A;
}

.left {
  text-align: left;
}

.padding20 {
  padding: rem(20px);
}

.ico {
  margin-left: rem(10px);
  margin-right: rem(10px);
  width: rem(50px);
  height: rem(50px);
}

.ico2 {
  margin-left: rem(10px);
  margin-right: rem(10px);
  width: rem(44px);
  height: rem(48px);
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-draw {
  flex-wrap: wrap;
}

.width {
  width: 100%;
}

.max-width {
  border: 0px solid red;
  width: 100%;
  padding: 0 0px;
}

.footer-menu {
  border-radius: rem(5px);
  flex: 1;
  padding: rem(20px);
  //background: rgba(204, 204, 204, 0.04);
  background: rgb(2, 0, 36);
  background: linear-gradient(145deg, rgba(2, 0, 36, 0.1) 0%, rgba(255, 255, 255, 0.06) 0%, rgba(142, 142, 142, 0.01) 20%);
  border: 0px solid red;
  text-align: left;
  //margin-right: rem(30px);
  box-shadow: 4px 4px 10px #0D0D0D10;
}

.footer-menu div a {
  color: #cccccc;
  border-radius: rem(50px);
  padding: rem(5.5px) rem(15px) rem(6px) rem(15px);
  font-family: CustomFontLight, sans-serif;
  text-decoration: none;
  display: inline-block;
  line-height: rem(20px);
}

.footer-menu div a.telegram {
  padding: rem(0px) rem(0px) rem(0px) rem(0px);
}

.footer-menu div a:hover {
  color: #eeeeee;
}

.footer-menu .link {
  cursor: pointer;
}

.footer-menu .link:hover {
  background-color: #575757;
}

.footer-menu .link:active {
  background-color: transparent;
}

.div-footer-contact {
  text-align: left;
  margin-left: rem(20px);
}

.footer-title {
  font-family: CustomFontMedium, sans-serif;
  margin-left: rem(15px);
  margin-bottom: rem(15px);
}

.div-contact-footer div {
  border-radius: rem(50px);
  padding: rem(4px) rem(15px) rem(6px) rem(15px);
  color: white;
  font-family: CustomFontRegular, serif;
  text-decoration: none;
  display: block;
}

.div-contact-footer div font {
  font-family: "Open Sans", sans-serif;
  color: #aaaaaa;
}

.footer-news-text {
  margin-left: 15px;
  color: #aaaaaa;
  font-size: 18px;
}

.nomargin {
  margin: 0 !important;
}

.footer-logo {
  width: rem(220px);
  height: rem(50px);
  margin-top: rem(-30px);
  margin-bottom: rem(10px);
  border: 0;
}

.footer-center {
  text-align: center;
}

.copyright {
  font-family: "Open Sans", serif;
}

h1 {
  font-size: rem(22px);
  margin: 0;
  margin-bottom: rem(5px);
  padding: 0;
  font-family: CustomFontRegular, sans-serif;
  font-weight: 700;
  border: 0px solid red;
}

h2 {
  font-size: rem(20px);
  margin: 0;
  padding: 0;
}

.border {
  border: 1px solid greenyellow;
}

.main-title {
  max-width: rem(800px);
  color: #4B4B4B;
  font-size: rem(16px);
}

.path {
  display: flex;
  flex-direction: row;
  margin-bottom: rem(30px);
}

.path a {
  text-decoration: underline;
  margin-right: rem(0px);
  cursor: pointer;
  color: black;
}

.path-separate {
  font-size: rem(16px);
  position: relative;
  margin-left: rem(10px);
  margin-right: rem(10px);
  margin-top: rem(1px);
}


input, textarea {
  line-height: rem(42px);
  width: 100%;
  box-sizing: border-box;
  padding: rem(12px);
  height: rem(42px);
  border-radius: rem(4px);
  border: rem(1px) solid #aaaaaa;
  margin-bottom: rem(10px);
  outline: none;
  font-weight: 300;
}

textarea {
  line-height: rem(14px);
}

input::placeholder {
  color: #aeaeae;
}

input:focus, textarea:focus {
  border: rem(1px) solid #24b47e;
}

textarea {
  height: rem(140px);
}


.button:hover {
  background-color: #26c388;

}

::-webkit-input-placeholder { /* Edge */
  color: #ccc;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ccc;
}

::placeholder {
  color: #ccc;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none; /* Remove default bullets */
}

li {
  //margin-left: rem(25px);
}

li::before {
  top: rem(5.5px);
  position: relative;
  font-size: rem(30px);
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #3aa2f1; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: rem(18px); /* Also needed for space (tweak if needed) */
  margin-left: rem(2px); /* Also needed for space (tweak if needed) */
}

.menu-display-mobile {
  display: flex;
  flex-direction: column;
}

.menu-display-mobile a, .mobile-menu-icon {
  display: block;
  padding: rem(16px) rem(30px);
  color: #222222;
  font-size: rem(16px);
  text-decoration: none;
  border-bottom: rem(1px) dotted #aaaaaa;
  background: #eeeeee;
}

.mobile-menu-icon {
  font-weight: 600;
  padding-left: rem(70px);
  background: #dddddd url('/img/menu.svg') rem(24px) center no-repeat;
  background-size: rem(40px);
}

.biglogo {
  background-color: #2f2e2e;
  width: rem(512px);
  height: rem(512px);
  background-image: url('/img/logo-royal.svg');
  background-size: 500%;
}

header .top .contact-container {
  text-shadow: #000000 0px 0px 5px;
  display: flex;
  flex-direction: column !important;
  color: white;
  line-height: rem(22px);
}

header .top .contact-container div {
  color: white;
}

header .top .contact-container a {
  color: white;
}

header .top .contact-container b {
  opacity: 0.8;
  font-weight: normal;
}
