@import './rem.scss';

@media (min-width: 320px) {
  .home-container .content-block {
    cursor: pointer;
    flex: 1;
    margin-bottom: rem(40px);

  }
  .home-container .content-block .title, .title {
    font-size: rem(20px);
    margin-top: rem(10px);
  }
  .home-container .content-block .text  {
    font-size: rem(16px);
    margin-top: rem(10px);
  }
  .home-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

@media (min-width: 720px) {
  .home-container .content-block {
    cursor: pointer;
    margin-bottom: rem(0px);

  }
  .home-container .content-block .title, .title {
    font-size: rem(18px);
    margin-top: rem(2px);
  }
  .home-container .content-block .text  {
    font-size: rem(15px);
  }
  .home-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: rem(30px);
  }
}



.home-container .content-block-image {
  flex: 1;
  height: rem(250px);
  -webkit-border-radius: rem(8px);
  -moz-border-radius: rem(8px);
  border-radius: rem(8px);
  -webkit-box-shadow: 0px rem(26px) rem(19px) rem(-20px) rgba(0, 0, 0, 0.31);
  -moz-box-shadow: 0px rem(26px) rem(19px) rem(-20px) rgba(0, 0, 0, 0.31);
  box-shadow: 0px rem(26px) rem(19px) rem(-20px) rgba(0, 0, 0, 0.31);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.home-container .content-block-image.b1 {
  background-size: cover;
  background-image: url('/img/img1.jpg');
}

.home-container .content-block-image.b2 {
  background-size: cover;
  background-image: url('/img/img2.jpg');
}

.home-container .content-block-image.b3 {
  background-size: cover;
  background-image: url('/img/img3.jpg');

}

.home-container .content-block-image.b4 {
  background-size: cover;
  background-image: url('/img/img4.jpg');

}

.home-container .content-block-image.b5 {
  background-size: cover;
  background-image: url('/img/img5.jpg');

}

.home-container .content-block-image.b6 {
  background-size: cover;
  background-image: url('/img/img6.jpg');
}

.content-block-image.b1:hover, .content-block-image.b2:hover, .content-block-image.b3:hover, .content-block-image.b4:hover, .content-block-image.b5:hover, .content-block-image.b6:hover{
  -webkit-transform:scale(1.06);
  -moz-transform:scale(1.06);
  -o-transform:scale(1.06);
}

.home-container {
  border: 0px solid red;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: rem(40px);
  margin-bottom: rem(30px);
}

.home-container .content-block {
  cursor: pointer;
  display: block;
  margin-top: 0;
  box-sizing: border-box;
  padding-left: rem(0px);
  padding-right: rem(0px);
}

.home-container .content-block .title, .title {
  font-family: CustomFontBold, sans-serif;
  margin-bottom: rem(4px);
  color: #2f2e2e;
}

.home-container .content-block .text {
  width: rem(290px);
  color: #797575;
  border: 0 solid green !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

