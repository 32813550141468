@import './rem.scss';

@media (min-width: 320px) {
  .start-container .warning-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    margin-left: rem(-10px);
    margin-top: rem(0px);
    line-height: rem(22px);
  }
  .start-container .warning-container .ico {
    display: none;
  }
  .start-container .step-container {
    position: relative;
    //margin-left: rem(160px);
    border: 0px solid red;
    width: 100%;
    margin-top: rem(24px);
    display: flex;
    flex-direction: column;
  }
  .start-container .text-container {
    width: 100%;
    font-size: rem(16px);
    margin-top: rem(50px);
    line-height: rem(22px);
  }
  .start-container .step-container .dd {
    font-size: rem(16px);
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

@media (min-width: 720px) {
  .start-container .warning-container {
    display: flex;
    flex-direction: row;
    width: rem(800px);
    position: relative;
    margin-left: rem(-10px);
    margin-top: rem(20px);
    line-height: rem(22px);
  }
  .start-container .warning-container .ico {
    display: block;
  }
  .start-container .step-container {
    position: relative;
    margin-left: rem(160px);
    border: 0px solid red;
    width: rem(700px);
    margin-top: rem(50px);
    display: flex;
    flex-direction: row;
  }
  .start-container .text-container {
    width: rem(800px);
    font-size: rem(14px);
    margin-top: rem(50px);
    line-height: rem(22px);
  }
  .start-container .step-container .dd {
    font-size: rem(14px);
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.start-container .warning-container .ico {
  background: url("/img/warning.svg") no-repeat center center;
  background-size: cover;
  height: rem(60px);
  margin-right: rem(20px);
  flex: 0 0 rem(60px);
}

.start-container .warning-container .text {
  color: #333333;
  font-size: rem(15px);
  text-align: left;
  flex: 1;
}

.start-container .step-container .img {
  height: rem(60px);
  line-height: rem(60px);
  text-align: left;
  display: block;
  padding-left: rem(65px);
  border: rem(0px) solid red;
  margin-bottom: rem(10px);
}

.start-container .step-container .img.step1 {
  background: url("/img/step1.svg") no-repeat left center;
  background-size: rem(50px);
}

.start-container .step-container .img.step2 {
  background: url("/img/step2.svg") no-repeat left center;
  background-size: rem(50px);
}

.start-container .step-container .img.step3 {
  background: url("/img/step3.svg") no-repeat left center;
  background-size: rem(50px);
}

.start-container .step-container .img.step4 {
  background: url("/img/step4.svg") no-repeat left center;
  background-size: rem(50px);
}

.start-container .step-container .img.step5 {
  background: url("/img/step5.svg") no-repeat left center;
  background-size: rem(50px);
}

.start-container .step-container .img.step6 {
  background: url("/img/step6.svg") no-repeat left center;
  background-size: rem(50px);
}


