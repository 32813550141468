@import './rem.scss';

@media (min-width: 320px) {
  .contact-img {
    display: none;
  }
  .contact-container .contact-items .item {
    flex: 0 0 rem(150px);
    border: 0px solid red;
  }
  .contact-container .contact-items .item.chat {
    background: url('/img/chatt.svg') rem(50px) 0 no-repeat;
    background-size: rem(60px);
  }
  .contact-container .contact-items .item.phone {
    background: url('/img/phone.svg') center 0 no-repeat;
    background-size: rem(60px);
  }
  .contact-container .contact-items {
     margin-top: rem(30px);
  }
  .contact-container .form-container .contact-box {
    margin-top: rem(30px);
    margin-bottom: rem(30px);
    flex: 1;
    text-align: left;
    width: 100%;
    border: 0px solid red !important;
    justify-content: center;
    box-sizing: border-box;
  }
  .contact-container .info {
    font-size: rem(14px);
    line-height: rem(21px);
  }
}
@media (min-width: 720px) {
  .contact-img {
    display: block;
  }
  .contact-container .contact-items .item {
    flex: 0 0 rem(200px);
  }
  .contact-container .contact-items .item.chat {
    background: url('/img/chatt.svg') rem(76px) 0 no-repeat;
    background-size: rem(60px);
  }

  .contact-container .contact-items .item.phone {
    background: url('/img/phone.svg') rem(74px) 0 no-repeat;
    background-size: rem(60px);
  }
  .contact-container .contact-items {
    margin-top: rem(50px);
  }
  .contact-container .form-container .contact-box {
    flex: 0 0 rem(400px);
    text-align: left;
    width: 100%;
    border: 0px solid red !important;
    margin: rem(30px);
    box-shadow: rem(0px) rem(0px) rem(8px) #00000018;
    padding: rem(30px);
    border-radius: rem(8px);
    justify-content: center;
    box-sizing: border-box;
  }
  .contact-container .info {
    font-size: rem(12px);
  }
}

.contact-container {
  box-sizing: border-box;
}

.contact-container .contact-items .item {
  height: rem(100px);
  padding-top: rem(70px);
  box-sizing: border-box;
}

.contact-container .form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
}



.contact-img {
  padding: rem(30px);
  border-radius: rem(8px);
  margin: rem(30px) rem(0px);
  flex: 0 0 rem(350px);
  background: url('/img/contact.jpg') center center no-repeat;
  background-size: cover;
}

.contact-container .contact-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 0px solid darkolivegreen;
}


.contact-container .info {
  font-family: "Open Sans", sans-serif;
  color: #1c6b8f;
}
