@import './rem.scss';

@media (min-width: 320px) {
  .faq-container .q {
    font-weight: 700;
    color: #24b47e;
    font-size: rem(18px);
    line-height: rem(22px);
    margin-bottom: rem(10px);
  }
  .faq-container .a {
    color: #2f2e2e;
    line-height: rem(20px);
    margin-bottom: rem(28px);
    font-size: rem(16px);
  }
}

@media (min-width: 720px) {
  .faq-container .q {
    font-weight: 700;
    color: #24b47e;
    font-size: rem(16px);
    line-height: rem(30px);
  }
  .faq-container .a {
    color: #2f2e2e;
    line-height: rem(20px);
    margin-bottom: rem(20px);
  }
}

.faq-container {
  margin-top: rem(30px);
  max-width: rem(800px);
  text-align: left;
}




