@import './rem.scss';

@media (min-width: 320px) {
  .terms-cointainer {
    display: flex;
    flex-direction: column;
  }
  .terms-box {
    margin: rem(0px);
    padding: rem(0px);
    margin-bottom: rem(30px);
  }
  .terms-cointainer .terms-box .title {
    font-size: rem(16px);
  }
  .terms-cointainer .terms-box .text {
    font-size: rem(16px);
    line-height: rem(24px);
  }
}

@media (min-width: 720px) {
  .terms-cointainer {
    display: flex;
    flex-direction: row;
  }
  .terms-box {
    margin: rem(20px);
    padding: rem(30px);
  }
  .terms-box:hover {
    box-shadow: rem(0px) rem(0px) rem(10px) #00000033;
  }
  .terms-cointainer .terms-box .title {
    font-size: rem(15px);
  }
  .terms-cointainer .terms-box .text {
    font-size: rem(14px);
  }
}


.terms-box {
  text-align: left;
  border-radius: rem(10px);
  min-height: rem(100px);
  flex: 1;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  //box-shadow: rem(0px) rem(0px) rem(10px) #00000033;
}

.terms-cointainer .terms-box .title {
  text-transform: uppercase;
  font-weight: 600;
  color: #24b47e !important;
  font-family: CustomFontBold, sans-serif;
  margin-bottom: rem(12px);
}

.terms-cointainer .terms-box .text {
  width: 100%;
  text-align: left;
  color: #333333;
}
