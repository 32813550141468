@import './rem.scss';


@media (min-width: 320px) {
  .price-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .price-container .item,  {
    margin-bottom: rem(20px);
  }
}
@media (min-width: 720px) {
  .price-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: rem(24px);
  }
  .price-container .item {
    margin-bottom: rem(0px);
  }
}

.price-container .item {
  box-shadow: rem(0px) rem(0px) rem(8px) #00000018;
  padding: rem(10px) rem(20px) rem(24px) rem(20px);
  border-radius: rem(8px);
}

.price-container h3  {
  color: #24b47e;
  font-size: rem(18px);
}

.price-container .price {
  font-size: rem(20px);
  color: #3aa2f1;
  margin-top: rem(24px);
  text-align: center;
}
