@import './rem.scss';


@media (min-width: 320px) {
  .content-container {
    width: 100%;
    display: flex;
    flex-direction: column;

  }
  .content-container.revers {
    flex-direction: column-reverse !important;
  }
  .content-container .content-block {
    margin: rem(0px);
    margin-bottom: rem(20px);
  }
  .content-container .content-block div {
    font-size: rem(16px);
    line-height: rem(24px);
    padding: rem(24px);
  }
}

@media (min-width: 720px) {
  .content-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .content-container.revers {
    flex-direction: row !important;
  }
  .content-container .content-block {
    margin: rem(20px);
  }
  .content-container .content-block div {
    font-size: rem(14px);
    line-height: rem(22px);
    padding: rem(40px);
  }
}

.content-container .content-block {
  border: 0px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: rem(0px) rem(0px) rem(8px) #00000018;
  border-radius: rem(10px);
  width: 100%;
  padding: rem(0px);
  min-width: rem(200px);
  min-height: rem(180px);

}

.content-container .content-block div {
  font-family: CustomFontRegular, serif;
  text-align: left;
}

.content-container .content-block h3 {
  text-align: left;
  font-family: CustomFontMedium, sans-serif;
  margin-bottom: rem(20px);
}

.content-container .content-block.pic1 {
  background: #ffffff url("/img/types1.jpg") center center no-repeat !important;
  background-size: cover !important;
}

.content-container .content-block.pic2 {
  background: #ffffff url("/img/types2.jpg") center center no-repeat !important;
  background-size: cover !important;
}

.content-container .content-block.pic3 {
  background: #ffffff url("/img/types3.jpg") center center no-repeat !important;
  background-size: cover !important;
}

.content-container .content-block.pic4 {
  background: #ffffff url("/img/types4.jpg") center center no-repeat !important;
  background-size: cover !important;
}

.content-container .content-block.pic6 {
  background: #ffffff url("/img/types6.jpg") center center no-repeat !important;
  background-size: cover !important;
}

.content-container .content-block.pic8 {
  background: #ffffff url("/img/types8.jpg") center center no-repeat !important;
  background-size: cover !important;
}

.content-container .content-block.pic9 {
  background: #ffffff url("/img/types9.jpg") center center no-repeat !important;
  background-size: cover !important;
}

.content-container .content-block .title {
  margin-bottom: rem(4px);
  font-size: rem(20px);
  font-weight: 800;
  font-family: CustomFontRegular, sans-serif;
  color: #3aa2f1 !important;
}

.content-container .content-block .text {
  width: rem(260px);
  font-size: rem(20px);
  color: #797575;
  border: 0 solid green !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

