@import './rem.scss';

@media (min-width: 320px) {
  .flex-news {
    flex-direction: column;
    display: flex;
    //background: #1c6b8f;
  }
  .news-container, .art-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: rem(25px);
    box-sizing: border-box;
  }
  .news-container .date {
    flex: 1;
    width: 100%;
    margin-bottom: rem(10px);
    border: 0px solid red !important;
    font-size: rem(16px);
    text-align: left;
    box-sizing: border-box;
    color: #aaaaaa;
  }
  .news-container .text {
    margin-right: rem(0px);
    margin-left: rem(0px);
    padding-top: rem(0px);

    font-size: rem(16px);
    border: 0px solid red;
  }
  .read {
    background: #3aa2f1;
    padding: rem(10px) rem(30px);
    font-size: rem(14px);
    display: table;
    margin-top: rem(20px);
    cursor: pointer;
    width: auto;
    color: #ffffff;
    border-radius: rem(40px);
    text-decoration: none;
  }
  .news {
    flex: 1;
    margin-right: rem(0px);
    box-sizing: border-box;
  }
  .art-container-shadow {
    display: none;
  }
  .art-pre-container {
    margin: rem(0px);
    margin-top: rem(0px);
    margin-bottom: rem(0px);
  }
  .art-container {
    padding: rem(0px);
    border: 0px solid red !important;
    margin-bottom: rem(0px);
    box-sizing: border-box;
  }
  .art-container .text, .art-container-shadow .text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: rem(0px);
    margin-top: rem(-3px);
    text-align: left;
    font-size: rem(16px);
    line-height: rem(22px);
    margin-right: rem(0px);
    box-sizing: border-box;
  }
  .news-container .title {
    font-size: rem(16px);
    font-family: CustomFontLight, "Open Sans", sans-serif;
    color: #444444 !important;
  }
  .art-container .text .title {
    font-size: rem(16px);
    box-sizing: border-box;
  }
  .news-item-container {
    text-align: left;
    line-height: rem(24px);
    width: 100%;
    font-size: rem(14px);
    margin: rem(10px) rem(0px) rem(50px) rem(0px);
  }
}

@media (min-width: 720px) {
  .flex-news {
    flex-direction: row;
    display: flex;
  }
  .news-container, .art-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: rem(25px);
    box-sizing: border-box;
  }
  .news-container .date {
    margin-bottom: rem(0px);
    flex: 0 0 rem(70px);
    border: 0px solid red !important;
    font-size: rem(13px);
    text-align: right;
  }
  .news-container .text {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: rem(20px);
    margin-left: rem(20px);
    font-size: rem(16px);
  }
  .read {
    padding: 0;
    background: transparent;
    font-size: rem(13px);
    display: table;
    margin-top: rem(10px);
    cursor: pointer;
    width: auto;
    color: #2f7992;
    text-decoration: none;
  }
  .news {
    flex: 1;
    margin-right: rem(20px);
    box-sizing: border-box;
  }
  .art-container-shadow {
    display: flex;
  }
  .art-pre-container {
    margin: rem(20px);
    margin-top: rem(0px);
    margin-bottom: rem(30px);
  }
  .art-container {
    padding: rem(20px);
    border: 0px solid red !important;
    height: rem(140px);
    margin-bottom: rem(20px);
    box-sizing: border-box;
  }
  .art-container .text, .art-container-shadow .text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: rem(0px);
    margin-top: rem(-3px);
    text-align: left;
    font-size: rem(14px);
    line-height: rem(18px);
    margin-right: rem(0px);
    box-sizing: border-box;
    border: 0px solid red;
  }
  .news-container .title {
    font-size: rem(14px);
    border: 0px solid red;
    font-family: CustomFontLight, "Open Sans", sans-serif;
    color: #444444 !important;

  }
  .news-item-container {
    text-align: left;
    line-height: rem(24px);
    width: rem(800px);
    font-size: rem(14px);
    margin: rem(10px) rem(50px) rem(50px) rem(50px);
  }
}

.news-container .text {
  line-height: rem(20px);
  flex: 1;
  font-weight: 600 !important;
  text-align: left;
  padding-bottom: rem(25px);
  border-bottom: rem(1px) solid #eeeeee !important;
  margin-top: rem(-1px);
  box-sizing: border-box;
}

.articles {
  flex: 1;
}

.art-container .img {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-transform: scale(1.6);
  -moz-transform: scale(1.6);
  -o-transform: scale(1.6);
  width: rem(200px);
  height: rem(160px);
  border-radius: rem(0px);
  cursor: pointer;

}

.art-container-shadow .img {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  width: rem(220px);
  height: rem(160px);
  margin-left: rem(-40px);
  border-radius: rem(0px);
  cursor: pointer;
  position: static;
}

.art-container .title {
  font-size: rem(20px);
  display: block;
  margin-bottom: rem(10px);
  color: #96f !important;
}

.read:hover {
  text-decoration: underline;
}

.art-container-shadow {
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  height: rem(140px);
  left: 0;
  z-index: 10;
  //background: #2f7992;
  position: relative;
  margin-top: rem(-160px);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  border-radius: rem(10px);
  cursor: pointer;
  border: 0px solid greenyellow;
}

.art-container-shadow:hover {
  box-shadow: rem(0px) rem(0px) rem(8px) #00000022;
  -webkit-transform: scale(1.11);
  -moz-transform: scale(1.11);
  -o-transform: scale(1.11);
}

.art-container-shadow:hover .img {

}

.date {
  font-family: "Open Sans", sans-serif;
  font-size: rem(14px);
  color: #888888;
}


.news-pre-container.index .news-separate {
  display: block;
  height: rem(35px);
}

.news-pre-container.item .news-separate {
  display: block;
  height: rem(20px);
}

.news-pre-container {
  flex: 1;
  box-sizing: border-box;
}

.art-container .left {
  margin-bottom: rem(30px);
}


